<script>
//   import Multiselect from 'vue-multiselect'

const dt = new Date;
const now = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;
export default {
    components:{},
    props:['service','ODS','send','pos','estimated','disable','planB'],
    data(){
        return {
            active:false,
            addPersonel:false,
            fields:[{poste:{poste:"Superviseur",toAsk:false,qte:1},Qte:2,checkQte:2,uom:{designation:'Personne'}}],
            selectedMaterials:[{equipment:"",qte:0,amount:0}],
            selectedProducts:[{equipment:"",qte:0,amount:0}],
            
            RH:[],
            postes:[],
            retrieveRH:false,
            retrieveEQP:false,
            EQP_amount:0,
            RH_amount:0,
        }
    },
    mounted(){
        
        // if(this.service[this.pos][0].quantity > 0){
            switch (this.pos) {
                case 'Prestation de Services':
                    this.service[this.pos].forEach(element => {
                        this.fields = [];
                        this.fields.push({
                            id:element.id,
                            poste:element.prestation,
                            qte:element.quantity,
                            amount:element.amount,
                            uom:element.prestation.uom,
                            prestQte:element.quantity_prest,
                            checkQte:element.quantity_prest,
                            checkAmount:0
                        })
                        this.getAmount()
                    })
                    break;
                case 'Acquisition Fournitures':
                    this.service[this.pos].forEach(element => {
                        this.selectedProducts = [];
                        this.selectedProducts.push({
                            id:element.id,
                            product:element.prestation,
                            qte:element.quantity,
                            uom:element.prestation.uom,
                            amount:element.amount,
                            prestQte:element.quantity_prest,
                            checkQte:element.quantity,
                            checkAmount:0
                        })
                        this.getAmount()
                    })
                    break;
            
                default:
                    break;
            }
        // }
        // else {
        //     this.PostesList = this.service[this.pos];
        //     this.ProductsList = this.service[this.pos];
        // }
    },
    methods:{
        getAmount(){
            var total = 0;
            if(this.pos == 'Prestation de Services'){
                 this.fields.forEach(element => {
                     element.checkAmount = parseInt(element.poste.unit_price) * parseInt(element.checkQte);
                     total += element.checkAmount;
                 })
                 this.emitData()
            }
            if(this.pos == 'Acquisition Fournitures'){
                 this.selectedProducts.forEach(element => {
                     element.checkAmount = parseInt(element.product.unit_price) * parseInt(element.checkQte);
                     total += element.checkAmount;
                 })
                 this.emitData()
            }

            return total;
        },
        emitData(){
            switch (this.pos) {
                case 'Prestation de Services':
                    this.$emit('getvalues',this.fields)
                    this.$emit('getvaluesEmitted',true)
                    break;
                case 'Acquisition Fournitures':
                    this.$emit('getvalues',this.selectedProducts)
                    this.$emit('getvaluesEmitted',true)
                    break;
            
                default:
                    break;
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return;
            }
        }
    }
    
}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <div class="accordion mb-2" role="tablist">
                <b-card no-body class="mb-1"  v-if="pos == 'Prestation de Services'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                        <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Prestation de Services</div>
                        <div class="col text-right">
                            <span>Nombre de Prestation demandé: {{fields.length}} / Montant Vérification: {{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(getAmount())}} </span>
                            <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                            <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                        </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <div class="p-3">
                                <div class="row">
                                    
                                    <div class="col">
                                    <b-card title="Personnel Reçu">
                                        <table class="table mt-2">
                                            <thead>
                                                <th>Poste</th>
                                                
                                                <th class="text-center">Nombres demandé</th>
                                                <th class="text-center">Nombres réalisé</th>
                                                <th class="text-center">Unité</th>
                                                <th class="text-right">Confirmer Nombres</th>
                                            </thead>

                                            <tr v-for="field in fields" :key="field.poste.id">
                                                <th> {{field.poste.name}} </th>
                                                
                                                <td class="text-center"> {{field.qte}} </td>
                                                <td class="text-center"> {{field.prestQte}} </td>
                                                <td class="text-center"> {{field.uom.designation}}</td>
                                                <td class="text-right" style="width:33%"> <input type="number" class="form-control" min="0" v-model="field.checkQte" @keypress="isNumber($event)" @input="getAmount()"></td>
                                            </tr>
                                        </table>
                                        
                                    </b-card>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
               
                <b-card no-body class="mb-1" v-if="pos == 'Acquisition Fournitures'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Produits</div>
                            <div class="col text-right">
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <table class="table mt-2">
                                <thead>
                                    <th>Produit</th>
                                    <!-- <th class="text-center">à la demander</th> -->
                                    <th class="text-center">Quantité demandé</th>
                                    <th class="text-center">Quantité envoyé</th>
                                    <th class="text-right">Confirmer Quantité</th>
                                </thead>

                                <tr v-for="product in selectedProducts" :key="product.product.id">
                                    <th> {{product.product.designation}} </th>
                                    <td class="text-center"> {{product.qte}} </td>
                                    <td class="text-center"> {{product.prestQte}} </td>
                                    <td class="text-right" style="width:33%"> <input type="number" class="form-control" min="0" v-model="product.checkQte" @keypress="isNumber($event)"></td>
                                </tr>
                            </table>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </div>
    </div>
</template>
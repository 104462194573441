<script>
import appConfig from "@/app.config";
import Multiselect from 'vue-multiselect'
import Swal from "sweetalert2";
// import Switches from "vue-switches";
import Rooms from "./rooms";
import {getDropDownElementsByTypeApi,fetchProductsApi} from "@/api/common";
const dt = new Date;
const now = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;

export default {
    page: {
        title: "Contrôle & Validation ODS Opérations 3D",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: {/*Switches,*/Rooms,Multiselect},
    props:{
      ODS:{
        type:Array,
        default:undefined
      },
      leftAmountContract:{}
    },
    data() {
      return {
      tableData: [],
      title: "Ordre de Service",
      selectedBdv:0,
      addPersonel:false,
      blocked:true,
      showProlongationModal:false,
      ODS_info: {
        ID_ODS:'',
        Bdv:0,
        ID_CTT:'',
        prestataire:0,
        date_ODS:'',
        date_exec_ODS:'',
        services:[],
        comments:''
      },
      nows:now,
      prolongationModal:{
        id:"",
        to:"",
        comments:""
      },
      switchers:[],
      bases:[],
      prests:[],
          
      fields:[{selctedPoste:"",Qte:""}],
      selectedContract:[],
      contracts:[],
      RepasService:[],
      selectedPrest:[],
      disabled:false,
      gs_services:[],
      selectedServices:[],
      items:{},
      planB:[],
      filtredRepas:[],
      estimated:"",
      diffDays:"",
      send:false
    };
  },
  watch:{
      ODS:{
      handler(val){
        // console.log(val);
        if(val[0] == undefined) {this.resetData(); this.have = false}
        else {
          this.ODS_info.id            = val[0].id;
          this.ODS_info.ref           = val[0].ref;
          this.ODS_info.type_ODS      = val[0].type_ODS;
          this.ODS_info.Bdv           = val[0].Bdv;
          this.ODS_info.ID_CTT        = val[0].contract.id;
          this.ODS_info.prestataire   = val[0].service_provider.id;
          this.ODS_info.date_ODS      = val[0].date_ODS;
          this.ODS_info.date_exec_ODS = val[0].date_exec_ODS;
          this.ODS_info.comments      = val[0].comment;
          this.ODS_info.period        = "";
          this.ODS_info.read_at       = val[0].read_at;
          this.ODS_info.done          = val[0].done;
          this.ODS_info.verified      = val[0].verified;
          this.ODS_info.status        = val[0].status;
          this.ODS_info.lifebase      = val[0].lifebase
          this.ODS_info.total_amount  = val[0].totalAmnt.plain
          this.ODS_info.items         = val[0].items
          this.RepasService           = Object.keys(val[0].items);
          this.filtredRepas           = val[0].items;
          // this.Repas = val[0].items
          
          this.selectedContract = val[0].contract;
          this.contracts.push(val[0].contract)
          this.selectedBdv = val[0].lifebase;
          this.bases.push(this.selectedBdv);
          this.selectedPrest = val[0].service_provider;
          // this.prestataire.push(val[0].service_provider);

          this.RepasService.forEach(element => {
              this.ODS_info.services.push(this.filtredRepas[element][0].service_types);
              this.gs_services.push(this.filtredRepas[element][0].service_types);
              this.selectedServices.push(this.filtredRepas[element][0].service_types);
              this.items[element] = undefined;
          })
          this.reformDat()
          this.$emit('getContractID',val[0].contract);
        }
        
      }
    },
    getEmit:{
      handler(val){
        this.getTotalAmountODS();
      }
    }
  },
  mounted(){
    
  },

  methods:{
    onComplete(){
      // this.getTotalAmountODS();
      Swal.fire({
            title: 'Êtes vous sûr ?',
            text: 'de vouloir valider le contrôle de cet ODS ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$http.post('/ods/OdsRestauration/validation',{
                  ods:this.ODS_info, items:this.items
              })
              .then(response => {
                  this.send = true;
                  this.$emit('odsvalues',true);
                  Swal.fire("Félicitations!", "Commande Contrôlé et Validé avec succées", "success");
                  this.resetData();
                  this.send=false;
              
              })
              .catch(error => console.log(error))
            }
          })
      
      
    },
    TodayDate(){
        var td, dt = new Date;
        td = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;

        return td;
    },
    resetData(){
        this.ODS_info = {
        type_ODS:'green_space',
        status:'draft',
        Bdv:0,
        ID_CTT:0,
        prestataire:0,
        date_ODS:now,
        date_exec_ODS:now,
        services:[],
        comments:''
      };
      this.selectedContract=[];
      this.selectedBdv=[];
      this.selectedPrest=[];
      this.RepasService = [];
      this.filtredRepas = [];
      this.planB = [];
      this.selectedServices=[];
    },
    getListGreenSpace_services(){
      getDropDownElementsByTypeApi('greenspacce_service_type')
          .then(res => {
            console.log(res);
            this.gs_services = res.data.original.list;
          })
          .catch(error => console.log(error))
    },
    getTotalAmountODS(){
      console.log(this.items);
      var total = 0
      var keys = Object.keys(this.items)
      keys.forEach(element => {
        if(this.items[element] && this.items[element] != undefined && Array.isArray(this.items[element])) {
          this.items[element].forEach( x => {
            // console.log(parseInt(x.poste.unit_price)* parseInt(x.checkQte));
            total += ( parseInt(x.poste.unit_price)* parseInt(x.checkQte) )
          })
        }
        
      })
      console.log(this.leftAmountContract);
      if(total > this.leftAmountContract){
          Swal.fire(
            'Dépassement',
            `Vous avez dépassé le montant restant du contrat de: ${new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(Math.abs(this.leftAmountContract - total))}`,
            'error'
          )
          // this.blocked = true;
      }  
      else{
        Swal.fire(
            'Correct',
            `Montant Restant du contract après validation: ${new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'DZD' }).format(this.leftAmountContract - total)}`,
            'success'
          )
          this.blocked=false
      }
      // console.log(total);
      this.getEmit=false;
      this.ODS_info.total_amount_verified = total;
      this.$emit('odsvalues',false);
      return total;
    },
    reformDat(){
      // console.log(this.ODS_info.date_exec_ODS.split('-'));
      let d1 = new Date(this.ODS_info.date_exec_ODS.split('-')[0],this.ODS_info.date_exec_ODS.split('-')[1]-1,this.ODS_info.date_exec_ODS.split('-')[2]);
      let d2 = new Date(this.ODS_info.date_ODS.split('-')[0],this.ODS_info.date_ODS.split('-')[1]-1,this.ODS_info.date_ODS.split('-')[2]);
      let diff = (d1.getTime() - d2.getTime()) / (1000*3600*24);

      this.diffDays = diff+" jours";
    },

    extendODS(){
      this.prolongationModal.id = this.ODS_info.id;
      this.$http.post('/ods/OdsRestauration/extendServiceOrder/'+this.ODS_info.id,this.prolongationModal)
      .then(response => {
        console.log(response);
        Swal.fire(
            'ODS Prolongé',
            `l'exécution de ${this.ODS_info.ref} a été prolongé` ,
            'success'
          );
        this.$emit('odsvalues',true);
        this.showProlongationModal=false;
      })
      .catch(error => {
        this.$toast.error('Une erreur s\'est produite, veuillez réessayer');
        console.log(error);
      })
    }
  }
    
}
</script>

<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <!-- <b-tabs content-class="mt-0">
          <b-tab title="Informations Générales" active> -->
                <div class="">
                  <div v-if="ODS_info.id && ODS_info.verified != 1" class="text-right">
                    <button class="btn btn-warning mt-2 mr-2" @click="showProlongationModal = true"> Ajouter Prolongement</button>
                  </div>
                <div class="">
                  <!-- <h4 class="card-title">New </h4> -->
                  
                    <div class="p-2">
                          <div>
                            <fieldset :disabled="!disabled">
                                <!-- <b-alert show variant="info"> <i class="mdi mdi-information-outline"></i> Les champs obligatoires sont marqués avec (*) </b-alert> -->
                                <div class="row">
                                      <div class="col-lg-4">
                                        <div class="form-group">
                                          <label for="formrow-password-input">N° Contrat</label>
                                          <multiselect v-model="selectedContract" :class="{'disabledMS':!disabled}" track-by="id" label="designation" placeholder="" :options="contracts" :searchable="false" :allow-empty="false">
                                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.designation }}</strong></template>
                                          </multiselect>
                                          
                                        </div>
                                      </div>

                                      <div class="col-lg-4">
                                        <div class="form-group">
                                          <label for="formrow-inputCity"
                                            >Nom de la Base *</label
                                          >
                                          <multiselect v-model="selectedBdv" :class="{'disabledMS':!disabled}" :disabled="bases.length == 1" :value="ODS_info.Bdv" track-by="id" label="name" placeholder="" :options="bases" :searchable="false" :allow-empty="false">
                                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name }}</strong></template>
                                          </multiselect>
                                          
                                        </div>
                                      </div>

                                      <div class="col-lg-4">
                                          <label for="">Prestataire *</label>
                                          <multiselect v-model="selectedPrest" :class="{'disabledMS':!disabled}" :disabled="true" :value="ODS_info.prestataire" track-by="id" label="prestname" placeholder="" :options="prests" :searchable="false" :allow-empty="false">
                                            <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.prestname }}</strong></template>
                                          </multiselect>
                                          
                                      </div>
                                  </div>
                                  
                                  <div class="row">
                                      <div class="col-lg-4">
                                          <label for="">Date ODS <i v-b-tooltip.hover.right="'Date où l\'éxecution commence'" class="fas fa-info-circle text-info"></i></label>
                                          <input type="date" class="form-control" v-model="ODS_info.date_ODS" >
                                          
                                      </div>
                                      <div class="col-lg-4">
                                          <label for="">Date execution <i v-b-tooltip.hover.right="'Date où l\'éxecution prendra fin'" class="fas fa-info-circle text-info"></i></label>
                                          <input type="date" class="form-control" v-model="ODS_info.date_exec_ODS">
                                          
                                      </div>
                                      <div class="col-lg-4" v-if="diffDays != 0">
                                          <label for="">Délai</label>
                                          <input type="text" class="form-control" v-model="diffDays" readonly>
                                          
                                      </div>
                                      
                                  </div>
                                  

                                  <div class="row mt-2">
                                      <div class="col-md-12">
                                        <div class="form-group">
                                          <label for="formrow-inputCity">Commentaires</label>
                                          <textarea
                                            v-model="ODS_info.comments"
                                            class="form-control"
                                            cols="30"
                                            rows="1" 
                                            readonly
                                          ></textarea>
                                        </div>
                                      </div>
                                  </div>

                                <!-- end row -->
                                </fieldset>
                          </div>
                          <!-- end card-body -->
                    </div>

                    <div class="p-3">
                      <!-- <resources/> -->
                      <div class="row mt-2" v-for="(rep,i) in RepasService" :key="i">
                        <div class="col">
                          <rooms
                              :disable="disabled" 
                              :key="rep" 
                              :pos="rep" 
                              :service="filtredRepas" 
                              @getvalues="items[`${rep}`] = $event" 
                              @getvaluesEmitted="getEmit = $event" />
                        </div>
                        
                      </div>
                    </div>

                    <div class="row">
                      <div class="col text-right mr-2 mb-3" v-if="ODS_info.id">
                        <b-button :disabled="ODS_info.verified == 1" @click="getTotalAmountODS" variant="info" class="btn-label mr-2">
                            <i class="bx bx-check label-icon"></i> Contrôler
                        </b-button>
                        <b-button :disabled="ODS_info.verified == 1 ||blocked" @click="onComplete" variant="primary" class="btn-label mr-2">
                            <i class="bx bx-check-double label-icon"></i> Enregitrer & Valider
                        </b-button>
                        
                      </div>
                    </div>

                    
                  
                </div>
                <!-- end card-body -->
              </div>
        
      </div>
      <Modal
          v-model="showProlongationModal"
          :title="`Prolongation:  ${ODS_info.ref}`"
        >
          <div class="row">
            <div class="col-md-4">
              <label for="">REF</label>
              <input
                class="form-control"
                type="text"
                disabled
                :value="ODS_info.ref"
              />
            </div>

            <div class="col-md-4">
              <label for="">Date Début Exécution</label>
              <input
                class="form-control"
                type="text"
                disabled
                :value="ODS_info.date_ODS"
              />
            </div>

            <div class="col-md-4">
              <label for="">Date Fin Exécution</label>
              <input
                class="form-control"
                type="text"
                disabled
                :value="ODS_info.date_exec_ODS"
              />
            </div>
          </div>

          <div class="row">
            <div class="col">
              <label for="">Prolonger Du:</label>
              <input type="date" class="form-control" v-model="ODS_info.date_exec_ODS">
            </div>
            <div class="col">
              <label for="">Prolonger Au:</label>
              <input type="date" class="form-control" v-model="prolongationModal.to">
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label for="">Commentaires</label>
              <textarea
                v-model="prolongationModal.comments"
                class="form-control"
                cols="30"
                rows="2"
              ></textarea>
            </div>

            <div class="col-md-12 mt-2">
              <button
                class="btn btn-primary float-right"
                @click="extendODS"
              >
                <i class="fas fa-save"></i> Enregistrer
              </button>
            </div>
          </div>
        </Modal>
    </div>
    <!-- </Layout> -->
</template>
